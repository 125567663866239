import { Button, Form, Select, Switch } from 'antd';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { TrpcBase } from '../../../utils/trpc';
import SelectVip from './SelectVip';
import { useGlobalMyInfo } from './useGlobalMyInfo';

export default function MyInfo() {
	const { data, isLoading } = TrpcBase.apartment.myInfo.useQuery();
	const { mutateAsync, data: iframeData } =
		TrpcBase.apartment.getToPayAction.useMutation();
	const [form] = Form.useForm();
	const payRef = useRef<HTMLDivElement>();

	const [stateParams, setStateParams] = useGlobalMyInfo();

	if (isLoading) {
		return <div>加载中</div>;
	}

	return (
		<div className="flex flex-col gap-3  bg-white p-2">
			{[
				{
					label: '用户id',
					field: 'id',
					isKey: true,
				},
				{
					label: '名称',
					field: 'name',
					isEdit: true,
				},
				{
					label: '用户名',
					field: 'username',
					// isEdit: true,
				},
				{
					label: '用户邮箱',
					field: 'primaryEmail',
				},
				{
					label: '上次登陆于',
					field: 'lastSignInAt',
					type: 'time',
					render: (i: string) => dayjs(i).format('YYYY-MM-DD HH:mm'),
				},
			].map((i) => {
				return (
					<div className="grid grid-cols-2 gap-3 w-1/2" key={i.label}>
						<div>{i.label}</div>
						<div>{i.render ? i.render(data[i.field]) : data[i.field]}</div>
					</div>
				);
			})}

			<Form
				form={form}
				initialValues={{
					...stateParams,
				}}
				onValuesChange={(v, all) => {
					setStateParams((pre) => {
						return {
							...pre,
							...all,
						};
					});
				}}
			>
				<Form.Item
					label="全局敏感信息隐藏"
					name="globalSensitiveInformationHiding"
				>
					<Switch />
				</Form.Item>
			</Form>

			<div className="flex flex-row gap-2 ">
				<Form
					className="mx-2"
					style={{
						maxWidth: '300px',
					}}
					initialValues={{
						payWay: 'alipay',
					}}
					onValuesChange={async (v) => {
						const res = await mutateAsync();

						const outputIframe1: HTMLIFrameElement =
							document.getElementById('output_iframe1');

						if (outputIframe1) {
							outputIframe1.src = 'about:blank';

							// 非常好，可以跑
							outputIframe1.src = `data:text/html;charset=utf-8,${encodeURI(res)}`;
							// setTimeout(() => {
							// 	output_iframe1?.
							// 		contentWindow?.document.write(res);
							// }, 200);
						}
					}}
				>
					<Form.Item label="选择套餐" name="vipCommodity">
						<SelectVip />
					</Form.Item>

					<Form.Item label="付款方式" name="payWay">
						<Select options={[{ label: '支付宝', value: 'alipay' }]} />
					</Form.Item>
				</Form>

				{/* biome-ignore lint/a11y/useIframeTitle: <explanation> */}
				<iframe
					id="output_iframe1"
					style={{ border: 'none', height: '400px' }}
				/>
			</div>
		</div>
	);
}
